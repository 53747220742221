<template>
  <a-modal :visible="visible" title="检测项" :width="800" :destroyOnClose="true" @cancel="onCloseModel" @ok="onConfirm">
    <a-row :gutter="[12, 12]">
      <a-col :span="24" style="width: 256px">
        <a-input v-model="searchForm.search" placeholder="名称" allowClear @pressEnter="search" />
      </a-col>
      <a-col :span="24" style="width: 100px">
        <a-button type="primary" icon="search" :loading="dataLoading" @click="search" style="width: 100%">查询</a-button>
      </a-col>

      <a-col :span="24">
        <a-table
          rowKey="id"
          size="small"
          :columns="columns"
          :dataSource="dataItems"
          :loading="dataLoading"
          :pagination="pagination"
          :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          @change="changeTable"
        >
          <template slot="action" slot-scope="value, item">
            <a-button size="small" @click="onSelectOne(item)">选择</a-button>
          </template>
        </a-table>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import { inspectionPointOption } from "@/api/option";

export default {
  props: ["visible"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      searchForm: { search: "", page: 1, ordering: undefined, is_enabled: true, type: "maintenance" },
      pagination: { current: 1, total: 0, pageSize: 10 },
      dataLoading: false,
      dataItems: [],

      selectedRowKeys: [],
      selectedRowItems: [],
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_value, _item, index) => index + 1,
        },
        {
          title: "名称",
          dataIndex: "name",
        },
        {
          title: "检验类型",
          dataIndex: "type_display",
        },
        {
          title: "检验工具",
          dataIndex: "tool",
        },
        {
          title: "备注",
          dataIndex: "remark",
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 60,
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    list() {
      this.dataLoading = true;
      inspectionPointOption(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.dataItems = data.results;
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    onCloseModel() {
      this.$emit("cancel", false);
    },
    changeTable(pagination, _filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      if (sorter.field) {
        this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      }
      this.list();
    },
    onSelectChange(selectedRowKeys, selectedRowItems) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRowItems = selectedRowItems;
    },
    onSelectOne(item) {
      this.$emit("select", [item]);
      this.onCloseModel();
    },
    onConfirm() {
      if (this.selectedRowItems.length === 0) {
        this.$message.warning("请选择保养项目");
        return;
      }

      this.$emit("select", this.selectedRowItems);
      this.onCloseModel();
    },
  },
  watch: {
    visible(status) {
      if (status) {
        this.dataItems = [];
        this.selectedRowKeys = [];
        this.selectedRowItems = [];
        this.search();
      }
    },
  },
};
</script>
